import * as React from 'react';
import $i18n from '../../i18n';

interface LogoProps {
  handleClick: () => void;
  title?: string;
  style?: React.CSSProperties;
  // width: number;
  // height: string;
  size?: number;
}

const Logo: React.FunctionComponent<LogoProps> = props => {
  const { title = '', style, size = 33, handleClick } = props;
  const width = size;
  const ratio = 29 / 33;
  const height = Math.round(size * ratio);
  const viewBox = `0 0 ${width + 3} ${height + 3}`;
  const fontSize = `${Math.round(18 * ratio)}px`;

  return (
    <div onClick={handleClick} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: '10px', ...style }}>
      {/* <svg
        viewBox="0 0 50 50"
        width="30px"
        height="30px"
      >
        <defs>
          <clipPath id="_clipPath_wkLPftco6dkRqy8mhwcnFIHWk6i7eUdT"><rect width="50" height="50" /></clipPath>
        </defs>
        <g clip-path="url(#_clipPath_wkLPftco6dkRqy8mhwcnFIHWk6i7eUdT)">
          <path
            d=" M 7.337 7.336 C 11.865 2.808 18.115 0 25 0 C 38.77 0 50 11.23 50 25 C 50 38.769 38.77 50 25 50 C 11.231 50 0 38.769 0 25 C 0 18.115 2.808 11.865 7.337 7.336 Z "
            fill-rule="evenodd"
            fill="#557daa"
          />
          <path
            d=" M 16.901 28.263 L 17.055 27.466 L 17.235 26.692 L 17.438 25.938 L 17.665 25.209 L 17.914 24.501 L 18.188 23.81 L 18.484 23.138 L 18.805 22.483 L 19.15 21.844 L 19.52 21.219 L 19.915 20.608 L 20.341 20.005 L 20.794 19.413 L 21.277 18.83 L 21.793 18.255 L 22.342 17.689 L 22.925 17.131 L 23.544 16.58 L 24.201 16.037 L 24.897 15.501 L 24.996 15.43 L 25.62 15.903 L 26.267 16.427 L 26.883 16.964 L 27.467 17.508 L 28.013 18.057 L 28.532 18.618 L 29.023 19.191 L 29.483 19.771 L 29.915 20.363 L 30.322 20.967 L 30.704 21.587 L 31.062 22.223 L 31.395 22.873 L 31.703 23.539 L 31.989 24.227 L 32.252 24.938 L 32.49 25.67 L 32.706 26.427 L 32.899 27.213 L 33.067 28.027 L 33.209 28.864 L 33.324 29.734 L 33.336 29.857 L 32.614 30.161 L 31.834 30.46 L 31.063 30.725 L 30.305 30.957 L 29.552 31.157 L 28.805 31.325 L 28.066 31.464 L 27.335 31.572 L 26.605 31.651 L 25.873 31.701 L 25.149 31.722 L 24.424 31.714 L 23.692 31.677 L 22.958 31.61 L 22.221 31.514 L 21.476 31.387 L 20.718 31.227 L 19.953 31.035 L 19.179 30.811 L 18.396 30.55 L 17.598 30.253 L 16.782 29.916 L 16.672 29.866 L 16.769 29.089 L 16.901 28.263 Z  M 15.88 11.884 C 18.332 9.366 21.362 7.152 25 5.257 C 28.085 6.871 30.779 8.735 33.062 10.859 C 39.419 16.777 42.587 24.722 42.143 34.95 C 31.013 42.009 19.592 42.422 7.857 34.95 C 7.471 25.663 10.018 17.905 15.88 11.884 Z "
            fill-rule="evenodd"
            fill="rgb(254,254,254)"
          />
        </g>
      </svg> */}
      <svg width="100px" height="30px" viewBox="0 0 801 220" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M79.4148 189.236C91.5873 189.236 102.275 186.267 111.776 180.032C121.276 173.798 126.027 164.742 126.027 152.718C126.027 146.038 122.761 140.249 116.229 135.35C109.698 130.6 101.83 126.444 92.6264 123.029C83.4228 119.764 74.2192 115.904 65.1641 111.748C55.9605 107.591 48.2413 101.802 41.7097 94.231C35.1782 86.6603 32.0608 77.3083 32.0608 66.3234C32.0608 46.7286 38.7408 30.845 52.1009 18.821C65.4609 6.79691 84.4619 0.710663 109.252 0.710663C117.12 0.710663 124.691 1.74978 132.113 3.67956C139.535 5.60935 145.621 7.83603 150.372 10.508C155.122 13.18 159.427 15.7036 162.989 18.3756C166.552 21.0476 169.372 23.2743 171.302 25.0557L173.677 28.0246L148.442 49.1037C147.403 47.7677 145.918 46.1348 143.84 44.0566C141.762 42.1268 137.16 39.4548 129.738 36.189C122.315 32.9232 114.299 31.2903 105.838 31.2903C93.2202 31.2903 83.4228 34.1108 76.5943 40.0486C69.7659 45.9864 66.3516 52.9633 66.3516 61.2762C66.3516 66.9172 68.5783 71.9643 73.3285 76.4176C77.9303 80.871 83.7197 84.4337 90.6966 87.1057C97.5251 89.7777 105.096 92.895 113.409 96.4577C121.573 100.02 129.144 103.732 135.972 107.591C142.801 111.451 148.59 116.795 153.34 123.772C157.942 130.749 160.317 138.765 160.317 147.968C160.317 169.938 152.747 187.455 137.902 200.369C123.058 213.136 102.275 219.667 75.5552 219.667C65.7578 219.667 56.5542 218.48 47.796 216.104C39.0377 213.729 31.9124 211.057 26.5683 207.792C21.0759 204.526 16.3256 201.408 12.1692 198.143C8.01271 194.877 4.89537 192.056 3.11403 189.978L0.442017 186.267L25.6777 165.336C25.9746 165.93 26.7168 166.672 27.6075 167.86C28.4981 169.047 30.4279 170.977 33.6937 173.649C36.8111 176.321 40.2253 178.845 44.0849 180.923C47.796 183.001 52.8431 184.931 59.2263 186.712C65.4609 188.494 72.2894 189.236 79.4148 189.236Z" fill="#3355FF"/>
          <path d="M323.167 154.203H208.716C209.162 164.742 213.021 173.649 220.147 181.071C227.42 188.494 236.624 192.205 247.757 192.205C256.219 192.205 264.383 190.275 272.251 186.415C280.267 182.556 286.056 178.696 289.619 174.54L295.111 168.75L311.292 189.236C310.698 190.127 309.807 191.166 308.471 192.65C307.135 194.135 304.315 196.51 299.862 199.924C295.557 203.338 290.955 206.307 286.205 208.979C281.454 211.651 275.368 214.026 267.946 216.253C260.524 218.48 253.101 219.519 245.382 219.519C227.569 219.519 212.131 213.136 199.216 200.072C186.153 187.158 179.77 171.868 179.77 154.203C179.77 130.452 188.379 110.115 205.896 93.0435C223.264 76.1207 243.749 67.5109 267.352 67.5109C285.166 67.5109 300.01 72.855 311.886 83.2461C323.761 93.6373 329.848 106.849 329.848 122.881C329.848 132.827 327.769 142.921 323.761 153.015L323.167 154.203ZM265.274 93.4888C254.883 93.4888 244.937 96.9031 235.436 103.435C225.936 109.966 218.959 118.279 214.209 128.373H300.307C300.752 118.279 297.932 109.966 291.697 103.435C285.462 96.9031 276.704 93.4888 265.274 93.4888Z" fill="#3355FF"/>
          <path d="M417.288 3.67956L372.309 216.55H341.878L387.005 3.67956H417.288Z" fill="#3355FF"/>
          <path d="M515.713 70.6283L509.478 97.9422H476.078L451.139 216.55H420.708L445.647 97.9422H424.419L430.505 70.6283H451.733L456.928 46.2833C463.163 16.0005 480.086 0.710663 507.4 0.710663C511.111 0.710663 514.822 1.00755 518.533 1.45289C522.096 2.04667 524.916 2.492 526.698 3.08578L529.815 3.67956L524.025 31.1419C519.572 29.2121 514.673 28.173 509.626 28.0246C504.282 28.0246 499.532 29.8059 495.376 33.2201C491.071 36.7828 488.399 41.2362 487.211 46.5802L482.312 70.6283H515.713Z" fill="#3355FF"/>
          <path d="M495.382 216.55L628.537 3.67956H661.937L705.134 216.55H671.14L660.156 160.289H565.893L531.157 216.55H495.382ZM638.186 41.6815L584.449 129.858H654.366L638.186 41.6815Z" fill="#00E5AC"/>
          <path d="M800.442 23.1259C800.442 28.4699 798.364 33.0717 794.504 37.2281C790.496 41.3846 785.746 43.4628 780.254 43.4628C775.058 43.4628 770.901 41.8299 767.784 38.861C764.518 35.7437 763.034 32.0326 763.034 27.5792C763.034 21.6414 764.964 16.8912 768.972 13.0316C772.831 9.17203 777.582 7.24224 783.074 7.24224C788.27 7.24224 792.426 8.7267 795.692 11.6956C798.809 14.6645 800.442 18.5241 800.442 23.1259ZM787.973 70.7767L756.948 216.698H726.516L757.541 70.7767H787.973Z" fill="#00E5AC"/>
      </svg>

      {/* <div style={{ color: `var(--text-color)`, fontSize: fontSize, fontWeight: '500', padding: '0px 4px' }}>
        {title}
      </div> */}
    </div>
  );
};

export default Logo;
